import React from 'react';
import { ReactComponent as BackgroundLogo } from 'assets/icons/backgroundLogo.svg';
import { Outlet } from 'react-router-dom';
import Header from 'components/Shared/Admin/Header';
import { SAdminLayout } from './Layout.styles';

const Layout = () => {
  return (
    <SAdminLayout>
      <BackgroundLogo className='background-logo' />
      <Header />
      <div className='admin-page-content'>
        <Outlet />
      </div>
    </SAdminLayout>
  );
};

export default Layout;
