import { TSelectOption } from 'types/global';
import { RoutePaths } from '../routes/Routes.types';

export enum PronounsEnum {
  'She/Her' = 1,
  'He/Him',
  'They/Them',
  'Ze/Zir/Zirs',
  'Fae/Faer/Faers',
  'Xe/Xem/Xir',
  'Any Pronoun(s)',
  'Prefer not to say',
}

export const customPronounceId = 9;
export const customGenderIdentityId = 16;
export const customSexualOrientationId = 11;
export const customUserRaceId = 9;

export const phoneNumberRegExp = /^(\d{3}-\d{3}-\d{4})$/;

export const pronounsOptions: TSelectOption[] = [
  { value: 0, label: 'She/Her' },
  { value: 1, label: 'He/Him' },
  { value: 2, label: 'They/Them' },
  { value: 3, label: 'She/They' },
  { value: 4, label: 'He/They' },
  { value: 5, label: 'Ze/Zir/Zirs' },
  { value: 6, label: 'Fae/Faer/Faers' },
  { value: 7, label: 'Xe/Xem/Xir' },
  { value: 8, label: 'Any Pronoun(s)' },
  { value: 9, label: 'Prefer to self describe:' },
];

export const genderIdentityOptions: TSelectOption[] = [
  { value: 1, label: 'Cisgender man' },
  { value: 2, label: 'Cisgender woman' },
  { value: 3, label: 'Trans man, transmasculine, or FTM' },
  { value: 4, label: 'Transwoman, transfeminine, or MTF' },
  { value: 5, label: 'Non-binary or Gender non-conforming/fluid/queer' },
  { value: 6, label: 'Two-spirit or Third gender' },
  { value: 7, label: 'Omnigender or pangender' },
  { value: 8, label: 'Intersex' },
  { value: 9, label: 'Agender' },
  { value: 10, label: 'Prefer to self describe:' },
];

export const sexualOrientationOptions: TSelectOption[] = [
  { value: 1, label: 'Heterosexual' },
  { value: 2, label: 'Lesbian' },
  { value: 3, label: 'Gay' },
  { value: 4, label: 'Bisexual' },
  { value: 5, label: 'Asexual' },
  { value: 6, label: 'Queer' },
  { value: 7, label: 'Pansexual' },
  { value: 8, label: 'Prefer to self describe:' },
];

export const ageGroupOptions: TSelectOption[] = [
  { value: 1, label: '16-17' },
  { value: 2, label: '18-26' },
  { value: 3, label: '27-34' },
  { value: 4, label: '35-44' },
  { value: 5, label: '45-54' },
  { value: 6, label: '55-64' },
  { value: 7, label: '65+' },
];

export const primarySpecialties: TSelectOption[] = [
  { value: 1, label: 'Allied Healthcare' },
  { value: 2, label: 'Behavioral/Mental Health' },
  { value: 3, label: 'Complementary/Alternative Health' },
  { value: 4, label: 'Dental Health' },
  { value: 5, label: 'Primary Care' },
  { value: 6, label: 'Specialist' },
];

export enum PrimarySpecialtiesEnum {
  'Allied Healthcare' = 1,
  'Behavioral/Mental Health' = 2,
  'Complementary/Alternative Health' = 3,
  'Dental Health' = 4,
  'Primary Care' = 5,
  'Specialist' = 6,
}

export const specialityIdentityOptions: TSelectOption[] = [
  { value: 1, label: 'Lesbian' },
  { value: 2, label: 'Gay' },
  { value: 3, label: 'Gay' },
  { value: 4, label: 'Bisexual' },
  { value: 5, label: 'Transgender' },
  { value: 6, label: 'Non-binary or Gender non-conforming/fluid/queer' },
];

export const languageOptions: TSelectOption[] = [
  { value: 1, label: 'English' },
  { value: 2, label: 'Chinese' },
  { value: 3, label: 'Hindi' },
  { value: 4, label: 'Spanish' },
  { value: 5, label: 'French' },
  { value: 6, label: 'Arabic' },
];

export const states: TSelectOption[] = [
  { value: 'Alabama', label: 'Alabama' },
  { value: 'Alaska', label: 'Alaska' },
  { value: 'Arizona', label: 'Arizona' },
  { value: 'Arkansas', label: 'Arkansas' },
  { value: 'California', label: 'California' },
  { value: 'Colorado', label: 'Colorado' },
  { value: 'Connecticut', label: 'Connecticut' },
  { value: 'Delaware', label: 'Delaware' },
  { value: 'Florida', label: 'Florida' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Hawaii', label: 'Hawaii' },
  { value: 'Idaho', label: 'Idaho' },
  { value: 'Illinois', label: 'Illinois' },
  { value: 'Indiana', label: 'Indiana' },
  { value: 'Iowa', label: 'Iowa' },
  { value: 'Kansas', label: 'Kansas' },
  { value: 'Kentucky', label: 'Kentucky' },
  { value: 'Louisiana', label: 'Louisiana' },
  { value: 'Maine', label: 'Maine' },
  { value: 'Maryland', label: 'Maryland' },
  { value: 'Massachusetts', label: 'Massachusetts' },
  { value: 'Michigan', label: 'Michigan' },
  { value: 'Minnesota', label: 'Minnesota' },
  { value: 'Mississippi', label: 'Mississippi' },
  { value: 'Missouri', label: 'Missouri' },
  { value: 'Montana', label: 'Montana' },
  { value: 'Nebraska', label: 'Nebraska' },
  { value: 'Nevada', label: 'Nevada' },
  { value: 'New Hampshire', label: 'New Hampshire' },
  { value: 'New Jersey', label: 'New Jersey' },
  { value: 'New Mexico', label: 'New Mexico' },
  { value: 'New York', label: 'New York' },
  { value: 'North Carolina', label: 'North Carolina' },
  { value: 'North Dakota', label: 'North Dakota' },
  { value: 'Ohio', label: 'Ohio' },
  { value: 'Oklahoma', label: 'Oklahoma' },
  { value: 'Oregon', label: 'Oregon' },
  { value: 'Pennsylvania', label: 'Pennsylvania' },
  { value: 'Rhode Island', label: 'Rhode Island' },
  { value: 'South Carolina', label: 'South Carolina' },
  { value: 'South Dakota', label: 'South Dakota' },
  { value: 'Tennessee', label: 'Tennessee' },
  { value: 'Texas', label: 'Texas' },
  { value: 'Utah', label: 'Utah' },
  { value: 'Vermont', label: 'Vermont' },
  { value: 'Virginia', label: 'Virginia' },
  { value: 'Washington', label: 'Washington' },
  { value: 'West Virginia', label: 'West Virginia' },
  { value: 'Wisconsin', label: 'Wisconsin' },
  { value: 'Wyoming', label: 'Wyoming' },
];

// export const states: TSelectOption[] = [
//   { value: 'AL', label: 'Alabama' },
//   { value: 'AK', label: 'Alaska' },
//   { value: 'AZ', label: 'Arizona' },
//   { value: 'AR', label: 'Arkansas' },
//   { value: 'CA', label: 'California' },
//   { value: 'CO', label: 'Colorado' },
//   { value: 'CT', label: 'Connecticut' },
//   { value: 'DE', label: 'Delaware' },
//   { value: 'FL', label: 'Florida' },
//   { value: 'GA', label: 'Georgia' },
//   { value: 'HI', label: 'Hawaii' },
//   { value: 'ID', label: 'Idaho' },
//   { value: 'IL', label: 'Illinois' },
//   { value: 'IN', label: 'Indiana' },
//   { value: 'IA', label: 'Iowa' },
//   { value: 'KS', label: 'Kansas' },
//   { value: 'KY', label: 'Kentucky' },
//   { value: 'LA', label: 'Louisiana' },
//   { value: 'ME', label: 'Maine' },
//   { value: 'MD', label: 'Maryland' },
//   { value: 'MA', label: 'Massachusetts' },
//   { value: 'MI', label: 'Michigan' },
//   { value: 'MN', label: 'Minnesota' },
//   { value: 'MS', label: 'Mississippi' },
//   { value: 'MO', label: 'Missouri' },
//   { value: 'MT', label: 'Montana' },
//   { value: 'NE', label: 'Nebraska' },
//   { value: 'NV', label: 'Nevada' },
//   { value: 'NH', label: 'New Hampshire' },
//   { value: 'NJ', label: 'New Jersey' },
//   { value: 'NM', label: 'New Mexico' },
//   { value: 'NY', label: 'New York' },
//   { value: 'NC', label: 'North Carolina' },
//   { value: 'ND', label: 'North Dakota' },
//   { value: 'OH', label: 'Ohio' },
//   { value: 'OK', label: 'Oklahoma' },
//   { value: 'OR', label: 'Oregon' },
//   { value: 'PA', label: 'Pennsylvania' },
//   { value: 'RI', label: 'Rhode Island' },
//   { value: 'SC', label: 'South Carolina' },
//   { value: 'SD', label: 'South Dakota' },
//   { value: 'TN', label: 'Tennessee' },
//   { value: 'TX', label: 'Texas' },
//   { value: 'UT', label: 'Utah' },
//   { value: 'VT', label: 'Vermont' },
//   { value: 'VA', label: 'Virginia' },
//   { value: 'WA', label: 'Washington' },
//   { value: 'WV', label: 'West Virginia' },
//   { value: 'WI', label: 'Wisconsin' },
//   { value: 'WY', label: 'Wyoming' },
// ];

export const paymentOptions: TSelectOption[] = [
  { value: 1, label: 'Free Services' },
  { value: 2, label: 'Sliding Scale' },
  { value: 3, label: 'Self-Pay' },
  { value: 4, label: 'Aetna' },
  { value: 5, label: 'Anthem' },
  { value: 6, label: 'Blue Cross Blue Shield' },
  { value: 7, label: 'Centene' },
  { value: 8, label: 'Cigna' },
  { value: 9, label: 'CVS Health' },
  { value: 10, label: 'GuideWell' },
  { value: 11, label: 'HCSC' },
  { value: 12, label: 'Humana' },
  { value: 13, label: 'Kaiser Permanente' },
  { value: 14, label: 'Medicaid' },
  { value: 15, label: 'Medicare' },
  { value: 16, label: 'Molina' },
  { value: 17, label: 'United Healthcare' },
];

export const PrivacyHyperlinks = {
  privacyPolicy: 'https://you-flourish.com/privacy-policy/',
  termsAndConditions: `${`${window.location.protocol}//${window.location.host}`}/app/${RoutePaths.TERMS}`,
  termsAndConditionsExternal: `${`${window.location.protocol}//${window.location.host}`}/${RoutePaths.TERMS}`,
  dataPolicy: '',
  cookiePolicy: '',
  aboutUs: 'https://you-flourish.com/',
};
