import React, { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import AuthLayout from 'components/Shared/AuthLayout';
import { adminPublicRoutes } from './Routes.data';
import { ADMIN_PRIVATE_BASE_URL, ADMIN_PUBLIC_BASE_URL, RoutePaths } from './Routes.types';

export const AdminPublicRoutes = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const admin = localStorage.getItem('admin');

    if (admin) {
      navigate(`${ADMIN_PRIVATE_BASE_URL}/${RoutePaths.ADMIN_MANAGEMENT}`);
    }
  }, []);

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path='/' element={<Navigate replace to={`${ADMIN_PUBLIC_BASE_URL}/${RoutePaths.LOGIN}`} />} />
          {adminPublicRoutes.map(({ path, Component }) => (
            <Route key={path} path={path} element={<Component />} />
          ))}
          <Route path='*' element={<Navigate replace to={`${ADMIN_PUBLIC_BASE_URL}/${RoutePaths.LOGIN}`} />} />
        </Route>
      </Routes>
    </Suspense>
  );
};
