import React, { KeyboardEvent, MouseEvent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { IUser } from 'models/admin-user';
import { PronounsEnum } from 'data/global';
import Button from 'components/Base/Button';
import AuthService from 'services/AuthService';
import Typography from 'components/Base/Typography';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Colors, FontSize, FontWeight } from 'styles/variables';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as PersonIcon } from 'assets/icons/person.svg';
import { ReactComponent as HamburgerIcon } from 'assets/icons/hamburger.svg';
import { ADMIN_PRIVATE_BASE_URL, ADMIN_PUBLIC_BASE_URL, RoutePaths } from 'routes/Routes.types';
import { menuItems } from './SideMenu.data';
import { SDrawer } from './SideMenu.styles';
import { TMenuItem } from './SideMenu.types';

const SideMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const admin: IUser = JSON.parse(localStorage.getItem('admin') || '{}');
  const [openMenu, setOpenMenu] = useState(false);
  const [activeTab, setActiveTab] = useState(
    menuItems.find((item) => item.path === location.pathname.split('/')[3])?.id || 1,
  );

  const toggleMenu = (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setOpenMenu(open);
  };

  const onMenuItemClick = (item: TMenuItem) => {
    if (item.path) {
      navigate(item.path);
      setActiveTab(item.id);
    }
  };

  const onViewProfile = () => {
    navigate(`${ADMIN_PRIVATE_BASE_URL}/${RoutePaths.PROFILE}`);
  };

  const onLogout = () => {
    AuthService.logout(navigate, { redirectPath: `${ADMIN_PUBLIC_BASE_URL}/${RoutePaths.LOGIN}` });
  };

  return (
    <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
      <div>
        {openMenu ? (
          <CloseIcon className='pointer align-middle' onClick={toggleMenu(false)} width={32} height={32} />
        ) : (
          <HamburgerIcon className='pointer align-middle' onClick={toggleMenu(true)} width={32} height={16} />
        )}
        <SDrawer anchor='left' open={openMenu} onClose={toggleMenu(false)} hideBackdrop>
          <div>
            <div className='user-info'>
              <Typography variant='h3'>
                {admin?.firstName} {admin?.lastName}
              </Typography>
              <Button fontSize={FontSize.FontSM} fontWeight={500} className='logout-btn' onClick={onLogout}>
                Logout
              </Button>
            </div>
            <div className='mb-6'>
              <Typography variant='body1' color={`${Colors.Black}88`} className='mb-2'>
                {admin?.email}
              </Typography>
              <Typography variant='body1' color={`${Colors.Black}88`}>
                {PronounsEnum[admin?.pronouns]}
              </Typography>
            </div>
            <div className='view-profile' onClick={onViewProfile}>
              <PersonIcon />
              <Typography variant='body1' color={`${Colors.Primary}`}>
                View Profile
              </Typography>
            </div>
            <div className='divider' />
            <div className='menu-items-container'>
              <ul className='menu-items'>
                {menuItems.map((item) => (
                  <li
                    key={item.id}
                    className={clsx('menu-item', { active: item.id === activeTab })}
                    onClick={() => onMenuItemClick(item)}
                  >
                    <span className='menu-item-icon'>{item.icon}</span>
                    <Typography
                      variant='body1'
                      color={`${Colors.Primary}`}
                      fontWeight={FontWeight.Bold}
                      className='menu-item-label'
                    >
                      {item.label}
                    </Typography>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </SDrawer>
      </div>
    </ClickAwayListener>
  );
};

export default SideMenu;
