import React from 'react';
import { ReactComponent as HeaderLogo } from 'assets/icons/authLogo.svg';
import SideMenu from '../SideMenu';
import { SHeader } from './Header.styles';

const Header = () => {
  return (
    <SHeader>
      <div className='header-content'>
        <SideMenu />
        <HeaderLogo width={215} height={34} />
      </div>
    </SHeader>
  );
};

export default Header;
