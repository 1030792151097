import { lazy } from 'react';
import { RoutePaths } from './Routes.types';

// Admin Public
const AdminLogin = lazy(() => import('pages/Admin/Auth/Login'));
const AdminForgotPassword = lazy(() => import('pages/Admin/Auth/ForgotPassword'));
const AdminResetPassword = lazy(() => import('pages/Admin/Auth/ResetPassword'));

// Admin Private
const AdminManagement = lazy(() => import('pages/Admin/AdminManagement'));
const Providers = lazy(() => import('pages/Admin/Providers'));
const AdminWellness = lazy(() => import('pages/Admin/Wellness'));
const AdminProfile = lazy(() => import('pages/Admin/Profile'));

// User Private
const ProviderDirectory = lazy(() => import('pages/ProviderDirectory'));
const Wellness = lazy(() => import('pages/Wellness'));
const Profile = lazy(() => import('pages/Profile'));
const CrisisSupport = lazy(() => import('pages/CrisisSupport'));
const Home = lazy(() => import('pages/Home'));

export const adminPublicRoutes = [
  { Component: AdminLogin, path: RoutePaths.LOGIN },
  { Component: AdminForgotPassword, path: RoutePaths.FORGOT_PASSWORD },
  { Component: AdminResetPassword, path: RoutePaths.RESET_PASSWORD },
];

export const adminPrivateRoutes = [
  { Component: AdminManagement, path: RoutePaths.ADMIN_MANAGEMENT },
  { Component: Providers, path: RoutePaths.PROVIDERS },
  { Component: AdminWellness, path: RoutePaths.WELLNESS },
  { Component: AdminProfile, path: RoutePaths.PROFILE },
];

export const userPrivateRoutes = [
  { Component: ProviderDirectory, path: RoutePaths.PROVIDER_DIRECTORY },
  { Component: Wellness, path: RoutePaths.WELLNESS },
  { Component: Profile, path: RoutePaths.PROFILE },
  { Component: CrisisSupport, path: RoutePaths.CRISIS_SUPPORT },
  { Component: Home, path: RoutePaths.HOME },
];
